body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F5F5EE;
    color: #222222;
    font-family: Avenir,sans-serif;
    font-weight: 450;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
}

h1 {
    font-family: Avenir, sans-serif;
    color: #222222;
    font-weight: 450;
    font-size: 36px;
}

p {
    margin-top: 40px;
}
  
.container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 205px;
    box-sizing: border-box;
}
  
#navbar {
  display: flex;
  padding: 22px 0;
  border-bottom: 1px solid #DEDEDE;
}

#navbar a {
  text-decoration: none;
  margin-left: 15px;
  background-color: #F5F5EE;
}

#navbar-links {
  display: flex;
  align-items: center; /* Center links vertically within this container */
}

#navbar a:first-of-type {
  margin-left: 25px;
}

/* Add specific styling for the Hire Me button */
.hire-me-button {
  margin-left: auto; /* Pushes this link to the far right */
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  line-height: 1.6;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 8px;
  padding-left: 7px;
  padding-right: 7px;
}

.hire-me-button:hover {
  background-color: #ff4500;
  cursor: pointer;
}

.hire-me-button {
  background-color: rgb(251 101 30);
}
  
.content {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
  
#sidebar {
    flex: 0 0 27.27%;
    border: 1px solid black;
    padding: 10px;
}
  
.right-content {
    flex: 1;
    box-sizing: border-box;
}
  
#sort-by {
    text-align: right;
}
  
#search-bar {
    margin-top: 0px;
    padding: 20px;
    margin-right: 0px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
}
  
#search-bar input {
    width: 100%;
    padding: 8px;
    border: 1px solid #C6C6C6; 
    border-radius: 5px;
}
  
#showing-text {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 3px;
    font-size: 14px;
}
  
#companies {
    border: 1px solid black;
    min-height: 100px;
    margin-top: 0px;
    overflow: hidden;
}

a {
    color: #222222; 
    text-decoration: none; 
}

a:hover {
    color: #268bd2;
}

#trending-link {
  color: #268bd2;
  font-weight: bold;
  text-decoration: underline;
}

#search-bar, #sidebar, #companies {
    border: 1px solid #C6C6C6; 
    background-color: #FDFDF8; 
    border-radius: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
}
  
.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}

#sidebar {
    align-self: flex-start; /* Align the sidebar based on content height */
    width: 20%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  
.right-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    box-sizing: border-box;
}
  
#search-bar,
#companies {
    margin-bottom: 20px;
}
  
#search-bar input {
    width: 100%;
    padding: 10px;
    height: 40px;
    font-size: 16px;
    box-sizing: border-box;
}

#sort-by {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    padding-right: 0px;
    padding-top: 35px;
    box-sizing: border-box;
}

#sort-by label {
    margin-right: 5px;
}

#sort-by select {
    font-family: Arial, sans-serif;
    font-size: 16px;
    border: 1px solid rgb(209 213 219);
    color: #6c6c6c;
    line-height: 24px;
    border-radius: 0.375rem;
    width: auto;
    box-sizing: border-box;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    background-position: right 0.5rem center;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 3rem 0.5rem 0.75rem;
    text-transform: none;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    cursor: default;
    margin-left: 0.3rem;
}

#showing-text {
  font-weight: 200;
}

#companies ul li {
    border-bottom: 1px solid #C6C6C6;
    padding-left: 0px; 
    margin-left: 0px;
    padding-top: 0px;
}  

#companies ul {
    padding-left: 0;
    margin-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
}
  
#companies ul li {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #C6C6C6;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 27px;
    margin-right: 0px;
}

#hire-me-content a {
  text-decoration: underline;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid darkgray;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
}

  .logo-container {
    flex: 0 0 90px; /* Fixed width of 90px */
    margin-right: 10px; /* Optional: Adds space between logo and company info */
    margin-bottom: 20px;
  }

  .logo {
    cursor: pointer; /* Changes the cursor to a hand (finger) on hover */
  }
  
  .company-details {
    display: flex;
    align-items: center;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%; /* Ensures the company-details takes up the full width */
  }

  .arrows-container {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    margin-left: auto;
    margin-right: 15px;
  }
  
  .info-container {
    flex: 1; /* Takes up the remaining width */
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    margin: 0 !important;
  }

  .info-container p {
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .description {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
  }
  
  .tags {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    margin-bottom: 20px;
  }
  
  .tag-pill {
    display: inline-flex;
    align-items: center;
    padding: 4px 10px;
    background-color: #E6E6DD;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    color: #333;
    transition: background-color 0.2s ease;
    letter-spacing: .1em;
    line-height: 12px;
  }
  
  .tag-pill:hover {
    background-color: #E5E7EB;
  }
  
  .tag-logo {
    width: 14px; /* Adjust this size to your needs */
    height: 14px; /* Match height to width to ensure it's square */
    margin-right: 5px;
    flex-shrink: 0; /* Prevent the logo from shrinking */
    vertical-align: middle;
  }
  
  .tag-pill img {
    /* Ensure the image doesn't increase the pill size */
    max-height: 100%;
    width: auto;
  }  

  .header {
    width: 100%;
    margin: 0 0;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    /*     align-items: center; */
  }
  
  .header p {
    padding-left: 10px;
    margin: 0;
    line-height: 1;
  }
  
  .header #location {
    margin-left: 10px;
    font-size: 12px;
    display: inline-block;
    vertical-align: bottom;
    line-height: 1.7; /* Adjust this value as needed */
  }

  .header #name {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    vertical-align: bottom;
  }

  .company-logo {
    width: 65px;
    height: 65px; /* Placeholder size for the logo */
    border-radius: 50%; /* Optional: Adds rounded corners */
    margin-left: 20px;
    /* object-fit: cover; /* Ensures the image covers the container without distortion */
  }

  /* NEW */
  
  .arrows-container {
    flex: 0 0 50px; /* Fixed width for the arrows container */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -20px; /* Adjust the value to move the arrows up */
  }
  
  .arrows-container button {
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .arrows-container svg {
    width: 20px;
    height: 20px;
  }

  .arrows-container p {
    margin: 0;
  }
  
  .arrows-container button svg {
    fill: #888
  }

  .arrows-container button:hover svg {
    fill: #222222;
  }

  .arrows-container button:disabled {
    cursor: default; /* Changes cursor to default when button is disabled */
  }

  .arrows-container button:disabled svg {
    fill: #222222;
  }

  .company-container {
    display: flex;
    flex-direction: row; /* Aligns company-details and arrows-container side by side */
    align-items: center; /* Vertically centers both company-details and arrows-container */
    width: 100%; /* Ensure the container takes up the full width */
  }

  .company-details {
    display: flex;
    flex-direction: row; /* Aligns elements inside company-details horizontally */
    align-items: center; /* Vertically centers the contents within company-details */
    flex: 1; /* Takes up the remaining space, allowing arrows-container to be on the right */
  }
  
  .logo-container {
    flex: 0 0 auto; /* Keeps the logo container to its natural size */
    margin-right: 20px; /* Space between logo and info */
  }

    .company-item {
        transition: background-color 0.3s ease;
    }
      
      .company-item:hover {
        background-color: #ffffff; /* White background on hover */
      } 

      #search-bar {
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start; /* Align items to the start (left) */
      }

      .search-pill-container {
        display:flex;
        flex-direction: row;
      }
      
      .search-pill {
        display: inline-flex;
        align-items: center;
        background-color: #FDFDF8; /* Set the background color */
        border: 1px solid #d3d3d3;
        padding: 0px 12px;
        border-radius: 16px;
        margin-right: 5px; /* Add space above the pill */
        margin-top: 10px;
        font-size: 13px;
        box-sizing: border-box;
      }
      
      .search-pill span {
        margin-right: 4px;
        text-transform: capitalize;
        font-size: 13px; /* Customize the font size if needed */
      }   
      
      .search-pill button {
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
        padding: 0px;
        color: inherit; /* Keep the same color as the text */
        line-height: 1; /* Ensure the "X" is vertically centered */
        font-weight: 50; /* Use a lighter font weight */
      }

      .sidebar-container {
        margin-top: 20px;
        margin-bottom: 0px;
        margin-right: 10px;
        margin-left: 10px;
        padding-bottom: 20px;
        height: auto; /* Let the height be determined by the content */
      }
      
      /* Ensure that the sidebar divs are stacked vertically */
      #sidebar {
        display: flex;
        flex-direction: column;
        height: auto; /* Let the height be determined by the content */
      }


      
      .checkbox-label-container {
        display: flex;
        align-items: center;
      }
      
      .checkbox-label-container input[type="checkbox"] {
        margin-right: 10px;
      }
      
      
      /* Optional styling for the link */
      .sidebar-container a {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
        display: block;
        margin-top: 10px;
      }
      
      .checkbox-label-container {
        display: flex;
        align-items: center;
      }
      
      .checkbox-label-container input[type="checkbox"] {
        margin-right: 10px;
      }
      
      .dropdown-icon {
        cursor: pointer;
        margin-right: 5px;
        font-size: 16px; /* Adjust the size as needed */
        transform: rotate(270deg); /* Default: pointing right */
        transition: transform 0.3s ease; /* Smooth rotation transition */
        color: #AFAFAF; /* Set color to AFAFAF */
      }
      
      .dropdown-icon.open {
        transform: rotate(0deg); /* Rotate 90 degrees to point down when open */
      }
      
      .dropdown {
        margin-left: 25px; /* Ensures alignment with the label text */
        margin-top: 5px; /* Adds space between the dropdown contents and the parent */
      }
      
      .dropdown div {
        display: flex;
        align-items: center;
      }
      
      
      .dropdown {
        margin-left: 25px; /* Ensures alignment with the label text */
        margin-top: 5px; /* Adds space between the dropdown contents and the parent */
      }
      
      .dropdown div {
        display: flex;
        align-items: center;
      }
      
      .tags-container {
        max-width: 600px;
        margin-top: 0px;
      }
      
      .search-bar {
        width: 100%;
        padding: 8px;
        margin-bottom: 20px;
        font-size: 16px;
      }
      
      .tags-list {
        max-height: 120px; /* Adjust this height as needed */
        min-height: 200px; /* This ensures the container doesn't shrink */
        overflow-y: scroll;
        padding-top: 0px;
        padding-bottom: 10px;
        padding-left: 0px;
        padding-right: 10px;
        border-radius: 5px;
      }
      
      .tag-checkbox {
        display: block; /* Ensure each checkbox takes up a full line */
        margin-bottom: 10px;
        margin-left: 0px;
      }
      
      .tag-checkbox input[type="checkbox"] {
        margin-right: 10px;
      }

      #batch-container {
        border-bottom: 1px solid #979797;
      }

      #top-companies-container {
        border-bottom: 1px solid #979797;
      }

      .sidebar-container label {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-weight: 200;
        font-size: 13px;
        line-height: 1em;
        padding-left: 0px;
      }

      .sidebar-container a {
        font-size: 13px;
        text-decoration: underline;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-weight: 200;
        margin: 0px;
        color: rgb(38 139 210 / var(--tw-text-opacity, 1)); /* Ensures the default opacity is 1 if the variable is not set */
      }

      .sidebar-container input[type="checkbox"] {
        -webkit-appearance: none; /* Removes default styling for WebKit browsers */
        -moz-appearance: none; /* Removes default styling for Firefox */
        appearance: none; /* Removes the default checkbox styling */
        width: 22px;
        height: 20px;
        cursor: pointer; /* Changes the cursor to a hand on hover */
        border: 1px solid #DDDDDD; /* Sets the border color */
        border-radius: 5px; /* Adds rounded corners */
        background-color: white; /* Ensures a white background */
        display: inline-block;
        position: relative;
        vertical-align: middle; /* Aligns the checkbox vertically with the text */
      }
      
      .sidebar-container input[type="checkbox"]:checked {
        background-color: #2463EB; /* Background color when checked */
        border-color: #2463EB; /* Border color when checked */
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M12 5L6.5 11L4 8.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        background-size: 18px 18px; /* Make the checkmark larger */
        background-position: center;
        background-repeat: no-repeat;
      }

      .batch-list {
        list-style-type: none; /* Removes bullet points */
        padding: 0; /* Removes default padding */
        margin: 0; /* Removes default margin */
      }

      .tag-checkbox {
        margin: 0px;
      }

      .tags-search-bar {
        font-size: 13px;
        line-height: 1em;
        border: 1px solid #ddd;
        padding: 6px 10px;
        border-radius: 4px;
        margin-bottom: 7px;
        width: 100%;
        background-color: #efefe8;
        box-sizing: border-box; /* Ensures the width includes padding and border */
      }
      

      .tags-search-bar input[type="text"] {
        padding: 4px;
        font-size: 13px;
      }

      .icon-button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        width: 15px;
        height: 15px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        box-shadow: none;
      }
      
      .icon {
        width: 15px;
        height: 15px;
        display: block;
      }

      h4 {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        padding-right: 0;
        padding-top: 0;       /* Remove top padding */
        padding-bottom: 7px;  /* Set minimal bottom padding */
        margin-top: 0;        /* Ensure there's no margin on top */
        margin-bottom: 0px;  /* Set minimal margin on bottom */
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: center;
      }
      
      .toggle-button {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        width: 15px;
        height: 15px;
        display: block;
        align-items: center;
        justify-content: center;
        margin-left: auto; /* Ensures the icon is pushed to the far right */
      
        /* Hide the button visually while keeping it clickable */
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        box-shadow: none;
      }
      
      .toggle-button svg {
        width: 15px;
        height: 15px;
        display: block;
      }
      
      .regions-container {
        max-width: 600px;
      }

      #region-container, #industry-container {
        border-bottom: 1px solid #979797;
        padding-bottom: 10px;
      }

      .region-checkbox input[type="checkbox"].indeterminate::before {
        content: "\2014"; /* Horizontal line */
        display: inline-block;
      }
      
      .region-checkbox {
        margin: 0px;
      }

      .industries-container {
        max-width: 600px;
        margin: 0 auto;  
      }

      .industry-checkbox input[type="checkbox"].indeterminate::before {
        content: "\2014"; /* Horizontal line */
        display: inline-block;
      }

      .industry-checkbox {
        margin: 0px;
      }
      
      #app-content {
        margin-left: 200px;
        margin-right: 200px;
      }

@media (max-width: 780px) {

  #app-content {
    margin-left: 11px;
    margin-right: 11px;
  }

  #navbar {
    display: flex;
    padding: 22px 0;
    border-bottom: none;
  }

  .hire-me-button {
    display: none;
  }

  #navbar a {
    display: none;
  }

  .mobile-nav-dropdown {
    align-items: center;
    display: flex;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
    line-height: 1.25;
  }

  .mobile-nav-button {
    padding: 0.5rem;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;
    box-shadow: none;
    outline: none;
    font-weight: normal; /* Set normal font weight */
    text-shadow: none;
    border: none;
    border-style: none;
    appearance: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.95rem;
    z-index: 100;
    transition: top 0.0s ease, right 0.0s ease; /* Smooth transition */
  }
  
  .mobile-nav-button.open {
    margin-top: 0 !important;
  }  

  .mobile-nav-button:hover {
    background-color: #f0f0f0; /* Darker shade on hover */
  }

  .navbar-button-svg {
    color: rgb(156 163 175);
    font-size: 100%;
    display: block;
  }

  .navbar-botton-svg:hover {
    color:rgb(86, 93, 105);
  }

  #navbar {
    padding-top: 1rem;
    position: relative;
    display: flex; /* Enable Flexbox for the navbar */
    flex-direction: column; /* Stack items vertically */
  }

  #navbar a {
    margin-left: 0px !important;
    background-color: white;
    font-size: 18px;
    font-weight: 200;
    font: Avenir;
    line-height: 3rem;
  }

  #navbar a:hover {
    background-color: #F3F4F6;
  }

  .navbar-panel {
    position: fixed; /* Fixed positioning relative to viewport */
    top: 10px; /* Start at the same top position as the button */
    right: 10px; /* Start at the same right position as the button */
    background-color: white; /* White background */
    transform-origin: top right; /* Expand from the button's position */
    transition: width 0.3s ease, max-height 0.3s ease, opacity 0.3s ease; /* Smooth transition for expansion */
    z-index: 99; /* Just below the button */
    padding: 0.5rem; /* Add padding around the content */
    padding-bottom: 2rem; /* Extra padding at the bottom */
    display: flex; /* Flexbox for content alignment */
    flex-direction: column; /* Vertical stacking of content */
    width: 0; /* Start with 0 width */
    max-height: 0; /* Start with 0 height */
    opacity: 0; /* Hidden by default */
    visibility: hidden; /* Prevent interaction when hidden */
    border: 2px solid #F3F4F6; /* Add plain border */
    box-shadow: none; /* Remove any box shadow */
    text-indent: 0.5rem;
  }
  
  .navbar-panel.open {
    opacity: 1; /* Fully visible when open */
    visibility: visible; /* Allow interaction when open */
    top: 10px; /* Keep top aligned with button */
    right: 10px; /* Keep right aligned with button */
    left: 10px; /* 10px space from the left side of the screen */
    width: auto; /* Allow width to adjust */
    max-height: 500px; /* Adjust to a large enough value to accommodate content */
    max-width: calc(100% - 2rem); /* Ensure panel doesn't overflow beyond 1rem on both sides */
    border: 2px solid #F3F4F6; /* Keep border consistent when open */
    box-shadow: none; /* Ensure no shadow appears when open */
  }  

  .panel-logo {
    position: absolute; /* Position it absolutely within the panel */
    top: 1rem; /* 1rem from the top */
    left: 1rem; /* 1rem from the left */
    width: 30px; /* Set logo width */
    height: auto; /* Maintain aspect ratio */
  }

  .inside-panel {
    position: absolute; /* Position inside the panel */
    top: 0px; /* Inside top */
    right: 0px; /* Inside right */
    margin-top: 0; /* Remove any top margin */
  }

  .panel-links {
    margin-top: 1rem; /* Add some space below the logo */
    display: flex;
    flex-direction: column; /* Stack links vertically */
    padding-top: 2rem;
  }
  
  .panel-link {
    text-decoration: none; /* Remove default underline */
    color: #333; /* Dark color for visibility */
    font-size: 1.2rem; /* Adjust font size */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
    display: block !important;
  }
  
  .panel-link:hover {
    color: #000; /* Darker color on hover */
  }

  #hero {
    padding-top: 0rem;
    padding-bottom: 1.5rem;
  }

  h1 {
    font-size: 30px;
    font-weight: 200;
    margin-bottom: 0.8em;
  }

  p {
    margin-top: 1.25em;
    font-size: .875rem;
    font-weight: 200;
  }

  #sort-by {
    padding-top: 1.5rem;
  }

  #sort-by label {
    font-weight: 200;
  }

  .main-content {
    margin-top: 0px;
  }

  #sidebar {
    display:none;
  }

  /* When sidebar is open, make it visible */
  #sidebar.open {
    display: block; /* Show sidebar */
  }

  .right-content {
    padding-left: 0px;
  }

  .mobile-filter-button {
    width: 100%;
    font-size: 18px;
    line-height: 1.6;
    margin: 0;
    color: #000;
    background-color: #fdfdf8;
    border: 1px solid #c6c6c6;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
  }

  #mobile-filter-button-content {
    margin-bottom: 25px;
  }

  /* Default styles for the sidebar */
#sidebar {
  width: 80%; /* Set the sidebar width to 80% of the page */
  max-width: 80%; /* Ensure it does not exceed 80% width */
  height: 100%; /* Full height */
  position: fixed; /* Fixed position for sliding effect */
  top: 0; /* Start at the top */
  left: -250px; /* Start off-screen */
  transition: left 0.3s ease; /* Smooth slide-in transition */
  z-index: 200; /* Ensure it's above other content */
  overflow-y: auto; /* Scrollable if content overflows */
  border-radius: 0;
  padding-bottom: 4rem;
  border-left: none;
  border-top: none;
}

/* Styles for when the sidebar is open */
#sidebar.open {
  left: 0; /* Slide in from the left */
}

.overlay {
  position: fixed; /* Cover the entire page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  z-index: 150; /* Ensure it appears behind the sidebar but above other content */
}

.mobile-only-button-container {
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  padding-top: 1rem; /* Add some padding */
  padding-left: 0.65rem;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 0; /* Stick to the bottom of the sidebar */
  width: 75%; /* Match the sidebar width */
  left: 0; /* Align */
  background-color: rgba(253, 253, 248, 0.8); /* Slightly transparent background color */
  backdrop-filter: blur(8px);
}

.mobile-show-companies-button {
  width: 100%;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  color: #000;
  background-color: #fdfdf8;
  border: 1px solid #c6c6c6;
  padding: 5px 10px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
}

.sidebar-container {
  overflow-y: auto; /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

}

#hero {
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 10px;
  padding-top: 25px;
}

#about-content {
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 10px;
  padding-top: 25px;
}

#hire-me-content {
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 10px;
  padding-top: 25px;
}

@media (min-width: 781px) {
  .mobile-nav-dropdown {
    display: none;
  }
  #mobile-filter-button-content {
    display: none;
  }
  .mobile-only-button-container {
    display: none; /* Hide by default */
  }
}

html, body {
  height: 100%; /* Allow full-height layout */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow: auto; /* Allow scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}
      
      
      
      
      
      
      
          
      
      
      
      
      
      
      
      
      
      
      
      
      
      